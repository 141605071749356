import React from 'react';
import {styled, ThemeProvider, Colors} from '@styles';
import {InlineYoutubeVideo} from '@components/Video';
import {FluidObject} from 'gatsby-plugin-image';
import {Section} from '@components/sectioning';
import HeaderBlock, {HeaderBlockProps} from '@components/HeaderBlock';
import RichTextCallout, {
  RichTextProps,
} from '@components/SectionCallout/RichTextCallout';
import { VideoBlock } from '@components/OrganicImageGrid/types';
import {useContainerSize} from '@components/layout';

interface Props {
  headerBlock?: HeaderBlockProps;
  headerbodyBlock?: RichTextProps;
  videoBlock?: VideoBlock
}

const Container = styled(Section).withConfig({
  componentId: 'iywhContainer'
})`
  display: flex;
  flex-direction: column-reverse;
  max-width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  .wide & {
    max-width: 75%;
    flex-direction: row;
  }
`;

const VideoContainer = styled.div.withConfig({
  componentId: 'iywhVideoContainer'
})`
  flex: 1;
`;

const Column = styled.div.withConfig({
  componentId: 'iywhDetailsColumn'
})`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: auto;
  padding: 0;
  width: 100%;
  .wide & {
    width: 50%;
    padding: 0 2em 4em 2em;
  }
`;

const LeftColumn = styled(Column).withConfig({
  componentId: 'iywhLeftColumn'
})`
  justify-content: flex-start;
  margin-top: 11em;
  position: relative;
`;

export default function InlineYoutubeWithHeader({
  ...props
}: Props): JSX.Element {
  console.log('inlineyoutube   props',   props)
  const {youtube, image, video} = props.videoBlock
  const headerbodyBlock = props.headerbodyBlock
  const headerBlock = null
  const {wide} = useContainerSize();
  return (
    <ThemeProvider
      theme={{
        fg: Colors.Charcoal,
        quoteFg: Colors.Blue,
        minPadding: '2em',
      }}
    >
      <Container>
        <LeftColumn>
          {headerbodyBlock && (
              <ThemeProvider
                theme={{
                  minPadding: wide ? '2em' : 0,
                  maxPadding: wide ? '4em' : 0,
                }}
              >
                <RichTextCallout {...headerbodyBlock} />
              </ThemeProvider>
            )}
        </LeftColumn>
        <VideoContainer>
          <InlineYoutubeVideo
            videoId={youtube}
            placeholderType={image ? `image` : video ? 'video' : null}
            placeholderImage={image ? image.childImageSharp : null}
            placeholderVideo={video ? video.url : null}
          />
        </VideoContainer>
      </Container>
    </ThemeProvider>
  )
};
