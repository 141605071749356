import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import useHover from '@hzdg/use-hover';
import {styled, Colors, ThemeProvider, theme, Fonts} from '@styles';
import {ResponsiveContainer} from '@components/layout';
import {ForwardLink} from '@components/Link';
import Image, {fluidImageFragment} from '@components/Image';

interface StudentSuccessCalloutProps {
  description: string;
  label?: string;
  url?: string;
}

const Container = styled(ResponsiveContainer).withConfig({
  componentId: 'studenSuccessCalloutContainer'
})`
  padding: 2.5em 2em 4.5em;
  margin: 0 auto;
  max-width: 100%;

  &.wide {
    max-width: ${theme.maxWidth};
  }
`;

const Description = styled.div.withConfig({
  componentId: 'studentSucessCalloutDescription'
})`
  ${Fonts.ProximaNova.variants.ExtraBold};
  font-size: ${34 / 16}em;
  line-height: ${40 / 34};
  letter-spacing: ${-1.09 / 34}em;

  .wide & {
    font-size: ${40 / 16}em;
    line-height: ${49 / 40};
    letter-spacing: ${-1.09 / 40}em;
  }
`;

const DescriptionContainer = styled.div.withConfig({
  componentId: 'studenSucessCalloutDescriptionContainer'
})`
  max-width: unset;
  grid-area: description;
  position: relative;
  z-index: 1;

  .wide & {
    max-width: 52%;
  }
`;

const Label = styled.div.withConfig({
  componentId: 'studenSuccessCalloutLabel'
})`
  font-weight: 600;
`;

const LinkContainer = styled.div.withConfig({
  componentId: 'studenSuccessLinkContainer'
})`
  margin-top: 1em;
`;

const ImageContainer = styled.div.withConfig({
  componentId: 'studenSuccessImageContainer'
})`
  transform: unset;
  margin-top: 2em;
`;

const MAP_QUERY = graphql`
  query MapImage($quality: Int) {
    mapImage: file(relativePath: {eq: "classroom-discussion.jpg"}) {
      ...fluidImageFragment
    }
  }
`;

const StudentSuccessCallout = ({
  description,
  url = '/approach/outcomes/student-success/',
  label = 'See student successes',
}: StudentSuccessCalloutProps): JSX.Element => {
  const data = useStaticQuery(MAP_QUERY);
  const [isHover, hoverProps] = useHover();
  return (
    <ThemeProvider
      theme={{
        maxWidth: '80%',
        ctaIconFg: Colors.Blue,
        ctaIconBg: Colors.Blue,
        ctaHoverFg: Colors.White,
        ctaHoverBg: Colors.Blue,
      }}
    >
      <Container>
        <DescriptionContainer>
          <Description>{description}</Description>
          <LinkContainer>
            <ForwardLink
              isHover={isHover}
              filledOnHover={true}
              href={url}
              {...hoverProps}
            >
              <Label>{label}</Label>
            </ForwardLink>
          </LinkContainer>
        </DescriptionContainer>
        <ImageContainer>
          <Image
            childImageSharp={data.mapImage.childImageSharp}
            alt="Graph showing all-time enrolled students totaling more than 300K."
          />
        </ImageContainer>
      </Container>
    </ThemeProvider>
  );
};

export default StudentSuccessCallout;
