import React from 'react';
import {InlineYoutubeWithHeader} from '@components/Video';
import {useContainerSize} from '@components/layout';
import {ThemeProvider} from '@styles';

export default function VideoWithBodySection({...props}): JSX.Element {
  const {wide} = useContainerSize();
  console.log('props', props)
  return (
    <ThemeProvider
      theme={
        props.theme
          ? Object.assign(
              {
                maxWidth: wide ? '80%' : '100%',
              },
              props.theme,
            )
          : {
              maxWidth: wide ? '80%' : '100%',
            }
      }
    >
      <InlineYoutubeWithHeader {...props.video} />
    </ThemeProvider>
  );
}
